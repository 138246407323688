.wrapper {
  align-items: center;
  display: flex;
}

.avatarAreaWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center
}

.avatar {
  border-radius: 50%;
  cursor: pointer;
  height: 50px;
  object-fit: cover;
  width: 50px; 
}

.userName {
  color: black;
  font-size: 14px;
  cursor: pointer;
  max-width: 160px;
  margin-left: 5px;
}

.menuItem {
  font-size: 16px;
  font-weight: 600;
}

.menu {
  background-color: white;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.exportBtnIcon {
  align-items:center;
  /* border-radius: 10px; */
  /* border: 2px solid #0E9AFC; */
  cursor: pointer;
  display:flex;
  justify-content:center;
  height: 24px;
  margin-right: 15px;
  width: 24px;
}

.exportBtn {
  align-items:center;
  border-radius: 10px;
  border: 2px solid #0E9AFC;
  cursor: pointer;
  display:flex;
  font-weight: 600;
  justify-content:center;
  height:36px;
  margin-right: 15px;
  width: 150px;
}

.notificationIcon {
  cursor: pointer;
  height: 24px;
  margin-right: 7px;
  width: 24px;
}

.notifWrapper {
  margin-bottom: 15px;
  max-width: 380px;
  position: relative;
}

.notifTitle {
  font-weight: 600;
  margin-bottom: 7px;
}

.notifDate {
  color: #A5ACB8;
  margin-top: 12px;
}

@media screen and (min-width: 360px) and (max-width: 1366px) {
  .exportBtn,
  .exportBtnIcon,
  .settingsItem {
    display: none;
  }
}

@media screen and (min-width: 360px) and (max-width: 766px) {
  .avatar {
    height: 30px;
    width: 30px; 
  }

  .userName {
    display: none;
  }
}
