@import '~antd/dist/antd.less';

* {
  font-family: 'Montserrat', sans-serif;
}

*::selection {
  color: black;
}

.MuiDataGrid-cell,
.MuiDataGrid-cell div,
.MuiDataGrid-cell span,
.MuiDataGrid-cell a,
.MuiDataGrid-columnHeaderTitle {
  font-family: 'Roboto', sans-serif !important;
}

.appWrapper {
  display: grid;
  background-color: #eef8ff;
  grid-template-columns: 265px auto;
  padding: 0px 20px 0px 20px;
  height: 100%;
}

.emptyPage {
  align-items: center;
  background-color: white;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.notifications .ant-popover-inner-content {
  padding: 15px 0px;
}

.react-flow__node-input {
  border-style: none !important;
}

.react-flow__node-input .react-flow__handle {
  display: none !important;
}

.react-flow__node-default {
  border-color: rgb(112, 112, 112) !important;
}

.react-flow__node.selected {
  border: 3px solid red !important;
  box-shadow: 0 0 0 0.5px red !important;
}

// annotation cards
.react-flow__node.annotation:hover {
  box-shadow: none !important;
}
.react-flow__node.annotation.selected {
  border: 0px !important;
  box-shadow: none !important;
}
.react-flow__node.annotation.selected svg path {
  fill: #0E9AFC;
  stroke:#0E9AFC;
}

.react-flow__controls-button {
  background: #0E9AFC !important;
  border-bottom: 1px solid rgba(151, 183, 5, 0.19) !important; 
}
.react-flow__controls-button:hover {
  background-color: #eef8ff !important;
}

.react-flow__edge-path {
  stroke: black !important;
}
.react-flow__edge.selected .react-flow__edge-path {
  stroke: red !important;
}

// ant styles

// ant select
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 55px;
  display: flex;
  align-items: center;
  border: 2px solid #0E9AFC;
  border-radius: 10px;
}
.ant-select-single.ant-select-focused.ant-select-show-arrow.ant-select-open .ant-select-selector {
  border: 2px solid #0E9AFC;
  border-radius: 10px;
}

.forFormikForms.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border: 2px solid black;
  border-radius: 5px;
  height: 56px;
}
.forFormikForms.ant-select-focused.ant-select-show-arrow.ant-select-open .ant-select-selector {
  border: 2px solid #0E9AFC;
}
.forFormikForms.placeholderIsShown.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  color: #9e9ea2;
}
.forFormikForms.hasError.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border: 2px solid #f44336;
}
.forFormikForms.hasWarning.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector  {
  border: 2px solid #FAAD14;
}

.customStyle.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 30px;
  display: flex;
  align-items: center;
  border: 2px solid #0E9AFC;
  border-radius: 10px;
}

.ant-select-selection-search-input {
  height: 55px !important;
}
.smallSelect .ant-select-selector {
  height: 35px !important;
}

.smallSelect .ant-select-selection-search-input {
  height: 35px !important;
}

.smallSelect.thinBorder.ant-select-single .ant-select-selector {
  height: 30px;
  display: flex;
  align-items: center;
  border: 1px solid #0E9AFC;
  border-radius: 10px;
}

// ant side menu
.ant-menu-inline {
  border-right: none;
}
.ant-menu-sub.ant-menu-inline {
  background-color: transparent;
}
.ant-menu-item,
.ant-menu-submenu-title {
  display: flex;
  align-items: center;
  padding-left: 15px !important;
}
.ant-menu-sub .ant-menu-item {
  padding-left: 35px !important;
}
.ant-menu-item:hover,
.ant-menu-submenu-title:hover,
.ant-menu-submenu-title i:hover {
  color: black !important;
}
.ant-menu-submenu-title span {
  margin-left: 10px !important;
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: black !important;
}
.ant-menu-submenu-selected {
  color: black !important;
}
.ant-menu-item-selected {
  background-color: transparent !important;
  border-left: 2px solid #0E9AFC;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #0E9AFC !important;
  font-weight: 600 !important;
  width: 250px !important;
}
.ant-menu-item-selected a {
  color: #0E9AFC !important;
}
.ant-menu-item-selected:hover {
  color: black !important;
}
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

// ant button
.ant-btn:hover {
  color: black;
}
.ant-btn-primary {
  border: none;
  box-shadow: none;
  color: black;
}
.ant-btn-primary:focus,
.ant-btn:focus {
  color: black !important; 
}
// ant-input 
.ant-input {
  border: 1px solid #0E9AFC;
}
.ant-input-affix-wrapper {
  border: 2px solid #0E9AFC !important;
}
// ant modal
.ant-modal-content {
  padding: 10px;
}
// ant tabs
.ant-tabs-tab-btn {
  color: #92c5f9 !important;
  font-weight: 600 !important;
}
.ant-tabs-tab-active .ant-tabs-tab-btn {
  background-color: rgba(14, 154, 252, 0.12);
  color: black !important;
  padding: 10px 30px 10px 30px;
}
.ant-tabs-tab:hover {
  color: #92c5f9;
}
.ant-tabs-ink-bar{
  background: #0E9AFC;
}
// ant select dark
.selectDark .ant-select-selector{
  border: 2px solid #373853 !important;
  border-radius: 3px !important;
}
.selectDark.hasError .ant-select-selector{
  border: 2px solid red !important;
}

// ant tale 
.ant-table table {
  color: black !important;
} 

.ant-table-cell,
.ant-table-cell div {
  font-family: 'Roboto', sans-serif !important;
}

.ant-table-thead tr th span {
  font-family: 'Roboto', sans-serif !important;
  font-weight: 600 !important;
}

.ant-table-thead tr th span.ant-checkbox-checked .ant-checkbox-inner,
.ant-table-tbody tr td span.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0E9AFC !important;
}

// ant datePicker
.forTable {
  border: 1px solid #0E9AFC;
  border-radius: 8px;
}
.forTable.error {
  border: 1px solid red;
}
.forTable:hover {
  border: 1px solid #0E9AFC;
}
.forTable.ant-picker-focused {
  border: 2px solid #0E9AFC;
}

// ant input
.inputForTable {
  border-radius: 8px;
}
.inputForTable:hover {
  border: 1px solid #0E9AFC;
}
.inputForTable.ant-input:focus {
  border: 2px solid #0E9AFC;
}

// ant slider
.timeline.ant-slider-disabled .ant-slider-track {
  background-color: #e2f3ff !important;
}

.timeline.ant-slider-disabled .ant-slider-dot {
  background-color: #0E9AFC !important;
}

.timeline.ant-slider-disabled .ant-slider-handle {
  background-color: #0E9AFC !important;
}

.timeline.ant-slider-disabled {
  cursor: default;
}

// ant popover
.ant-popover-message {
  align-items: center;
  display: flex;
}

.ant-popover-message-title {
  padding-left: 5px;
}

// material input select 
  .MuiFormLabel-root.Mui-disabled {
    color: #373853 !important;
  }

// material input checkbox
.MuiCheckbox-colorPrimary.Mui-checked {
  color: rgb(14, 154, 252) !important;
}

// material table
.MuiDataGrid-root .MuiDataGrid-colCellMoving {
  background-color: transparent !important;
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: 600 !important;
}
.MuiDataGrid-root {
  // border: none !important;
  box-shadow: 
  0px 1px 3px rgba(0, 0, 0, 0.14), 
  0px 1px 3px rgba(0, 0, 0, 0.12), 
  0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 15px !important;
}
.MuiDataGrid-root .MuiDataGrid-toolbarContainer .MuiButtonBase-root {
  border: 2px solid #0E9AFC;
  border-radius: 10px;
  color: black !important;
  padding: 4px 17px 4px 17px !important;
  position: absolute;
  top: -60px;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: solid transparent 1px !important;
}

.MuiDataGrid-menuIcon  {
  display: none !important;
}
.MuiButton-textPrimary {
  background-color: none !important;
  color: black !important;
  font-weight: 600 !important;
}
.MuiButton-textPrimary:hover {
  background-color: transparent !important;
}
.MuiDataGrid-colCellTitle {
  font-weight: 600 !important;
}
.MuiTouchRipple-rippleVisible {
  opacity: 0 !important;
}
.MuiSwitch-colorPrimary.Mui-checked {
  color: #0E9AFC !important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #0E9AFC !important;
}
.MuiInput-underline:after {
  border-bottom: 1px solid black !important;
}
.MuiDataGridPanel-root {
  background-origin: 15px;
}
.MuiTableCell-root {
  padding: 10px !important;
}

// material input
.MuiFormControl-root {
  height: 90px !important;
}
.MuiFormHelperText-root.Mui-error,
.MuiInputBase-input,
.MuiInputLabel-formControl {
  font-family: 'Montserrat' !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.autoWidthInput.MuiInputBase-formControl input {
  width: 100%;
}

// material datePicker
.MuiPaper-root.MuiPaper-rounded .MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-daySelected {
  background-color: #0E9AFC;
}
.MuiPaper-root.MuiPaper-rounded .MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-current {
  color: #0E9AFC;
}
.MuiPaper-root.MuiPaper-rounded .MuiButtonBase-root.MuiIconButton-root.MuiPickersDay-day.MuiPickersDay-current.MuiPickersDay-daySelected {
  color: white;
}

// orgchart
.orgchart > ul > li > ul li::before {
  border-top: 5px solid black !important;
}
.orgchart > ul > li > ul li .oc-node::before,
.orgchart ul li .oc-node:not(:only-child)::after {
  background-color: black !important;
}
.orgchart .oc-node .oc-edge::before {
  border-color: black !important;
}
.orgchart ul li .oc-node:hover {
  background-color:rgba(14, 154, 252, 0.3) !important;
  border-radius: 10px;
}
.orgchart ul li .oc-node.selected {
  background-color: rgba(14, 154, 252, 0.3) !important;
  border-radius: 10px;
}

.miniMapNavigation {
  bottom: 0px;
  left: 220px;
  position: absolute;
  z-index: 10;
}

.miniMapNavigation svg {
  border-radius: 30px;
}

@media screen and (min-width: 767px) and (max-width: 1366px) {
  .appWrapper {
    grid-template-columns: 55px auto;
  }

  .ant-menu-inline-collapsed > .ant-menu-item {
    left: 12px;
  }

  .ant-drawer .ant-drawer-content {
    overflow: visible;
  }
}

@media screen and (min-width: 360px) and (max-width: 766px) {
  .appWrapper {
    grid-template-columns: 100%;
    padding: 0px 5px 30px 5px;
    height: auto;
  }

  .ant-menu-inline-collapsed > .ant-menu-item {
    left: 12px;
  }

  .ant-drawer .ant-drawer-content {
    overflow: visible;
  }
}

@primary-color: #DBF0FF;