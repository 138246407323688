.uploadPreview {
  position: relative;
}

.uploadPreview .previewTools {
  display: none;
}

.uploadPreview:hover .previewTools {
  align-items: center;
  background: linear-gradient(0deg, rgba(12, 12, 12, 0.5), rgba(12, 12, 12, 0.5));
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}

.uploadPreview:hover .previewTools svg {
  cursor: pointer;
}
