.title {
  align-items: center !important;
  border-radius: 25px !important;
  display: flex !important;
  font-size: 30px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  height: 50px !important;
  justify-content: center !important;
  width: 360px !important;
  z-index: 100 !important;
}

.titleCirclesView {
  align-items: center !important;
  border-radius: 25px !important;
  display: flex !important;
  font-size: 30px !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  height: 150px !important;
  justify-content: center !important;
  width: 300px !important;
  z-index: 100 !important;
}
