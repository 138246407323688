.tableIcon {
  cursor: pointer;
}

.tableIsActiveColumn {
  align-items: center;
  border-radius: 5px;
  display: flex;
  font-weight: 600;
  height: 70%;
  justify-content: center;
  width: 100%;
}

.modalTitle {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 25px;
}

.form {
  display: flex;
  flex-direction: column;
  width: 720px;
}

.inputsPair {
  display: flex;
  justify-content: space-between;
  width: 98%;
}

.buttonsArea {
  display: flex;
  justify-content: flex-end;
  width: 97%;
}

.rolesWrap {
  border: 2px solid black;
  border-radius:5px;
  display:flex;
  justify-content:space-around;
  margin-bottom:10px;
  padding:10px;
  position:relative;
  width:97%;
}

.roleAreaTitle {
  background-color: white;
  font-size: 13px;
  font-weight: 600;
  top: -13px;
  left: 10px;
  padding: 0px 4px;
  position: absolute;
}

.rolesWrapError {
  border: 2px solid #f44336;
}
.rolesWrapError .roleAreaTitle {
  color: #f44336;
}
.rolesErrorText {
  color: #f44336;
  font-size: 12px;
  margin-left: 15px;
  margin-top: -5px;
}

.generateBtn {
  border: 2px solid #0E9AFC;
  border-radius: 15px;
  cursor: pointer;
  font-weight: 600;
  right: 13px;
  padding: 3px;
  position: absolute;
  top: 13px;
}
